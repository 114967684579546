<template>
  <sub-page
      v-model="$store.state.discount.pageData"
      :title="$t('PromoCodes') "
      icon="mdi-gift-outline"
      :header-action-text="'+ ' + $t('discount.create')"
      :header-action="openAddDiscount"

  >

    <template #default>
      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :row-action="openEditDiscount"
      >
        <template #item.name="{item}">
          <h5 :class="[{wsDARKLIGHT : !item.is_active}]"> {{ item.name }} <span class="wsDARKLIGHT" v-if="item.is_disposable">({{ $t('IsDisposable') }})</span></h5>
        </template>

        <template #item.percent="{item}">
          <h4>{{ item.percent }} %</h4>
        </template>

        <template #item.code="{item}">
          <v-chip @click.stop="copy(item.code)" :color="wsACCENT" dark>
            <v-icon small class="mr-1">mdi-content-copy</v-icon>
            <h5> {{ item.code }}</h5>
          </v-chip>
        </template>

        <template #item.range="{item}">
          <h5> {{ $t(`discount.${item.range}.title`) }}</h5>
        </template>

        <template #item.date_start="{item}">
          <h5 v-if="!item.date_start"> {{ $t(`NoRestrictions`) }}</h5>
          <h5 v-else>
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ PARSE_DATE(item.date_start , false, true)}}
          </h5>
        </template>

        <template #item.date_end="{item}">
          <h5 v-if="!item.date_end"> {{ $t(`NoRestrictions`) }}</h5>
          <h5 v-else>
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ PARSE_DATE(item.date_end , false, true)}}
          </h5>
        </template>

        <template #item.is_active="{item}">
          <ft-select
              :items="CHOICE_ACTIVE"
              @input="editActiveStatus(item,$event)"
              fill-height
          >
            <v-chip dark :color="item.is_active ? wsSUCCESS : 'grey'">
              <h5 class="text-no-wrap pl-2">
                {{ item.is_active ? $t('Active') : $t('NotActive') }}
                <v-icon>mdi-chevron-down</v-icon>
              </h5>
            </v-chip>
          </ft-select>

        </template>

        <template #item.action="{item}">
          <v-btn @click.stop="openDelete(item.uuid)" :color="wsACCENT"  icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>


      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          @save="saveDiscount"
          @delete="deleteDiscount"
          v-if="displayDialog"
          v-model="displayDialog"
          :title="$t('discount.create')"
          :show-delete="!newDiscount"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-text-field
            class="mt-5"
            @change="validateCode"
            v-model="entityData.code"
            :label="$t('Code')"
            :placeholder="$t('Code')"
            :error="codeError"
            @input="codeError = false"
            clearable
        />

        <ws-select
            class="mt-5"
            v-model="entityData.range"
            :items="rangeTypes"
            :label="$t('discount.range.choose')"
            :placeholder="$t('discount.range.title')"
        />

        <v-expand-transition>
          <ws-select
              v-if="entityData.range === 'course'"
              class="mt-5"
              v-model="entityData.courses"
              :items="coursesSelect"
              :label="$t('Course')"
              :placeholder="$t('ChooseCourse')"
              :multiple="true"
          />
        </v-expand-transition>

        <a-parameter-slider
            class="mt-2"
            v-model="entityData.percent"
            label="Percent"
            max="100"
            min="1"
        >

        </a-parameter-slider>
        <v-row no-gutters>

          <v-col cols="6">
            <ws-switch class="mt-6" v-model="entityData.is_active" :placeholder="entityData.is_active ? $t('Active') : $t('NotActive')" />
          </v-col>
          <v-col cols="6">
            <ws-switch class="mt-6" v-model="entityData.is_disposable" :placeholder="$t('IsDisposable')" />
          </v-col>

        </v-row>

      </ws-dialog>
      <ws-dialog
          :title="$t('DeletePromoCode')"
          v-model="displayDelete"
          @delete="deleteDiscount(selectedDiscountUuid)"
          display-confirm-delete
          close-delete
      />
    </template>

  </sub-page>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "promoCodes",
  data() {
    return  {
      items : [],
      entityData : {
        courses : []
      },
      displayDialog : false,
      newDiscount : false,
      coursesSelect : [],
      codeError : false,

      selectedDiscountUuid : null,
      displayDelete : false,
    }
  },
  computed : {
    subscriptionPeriodsSelect() {
      let items = []

      if (this.entityData.range !== 'course') {
        for (let i = 1; i <= 24; i++) {
          items.push({text: i, value: i})
        }
      } else {
        if ( Array.isArray(this.entityData.courses) && this.entityData.courses.length === 1 ) {
          let course = this.coursesSelect.find(el => el.value === this.entityData.courses[0])
          if ( course ) {
            course.time_periods_select.forEach(period => {
              items.push({text: period, value: period})
            })
          }
        }

      }

      return items
    },
    rangeTypes() {
      return [
        { text : this.$t('discount.global.title') , value : 'global' },
        { text : this.$t('Course') , value : 'course' },
      ]
    },
    discountTypes() {
      return [
        { text : this.$t('Default') , value : 'default' },
        { text : this.$t('Subscription') , value : 'subscription' },
      ]
    },
    discountTypesFiltered() {
      let items = JSON.parse(JSON.stringify(this.discountTypes))

      if ( this.entityData.range === 'course' ) {
        items.splice(1,1)

        if (this.coursesSelect.length > 0 ) {

          if ( Array.isArray(this.entityData.courses) && this.entityData.courses.length === 1 ) {
            let course = this.coursesSelect.find(el => el.value === this.entityData.courses[0])
            if ( course ) {
              if ( course.is_subscription) {
                items.push(  { text : this.$t('Subscription') , value : 'subscription' })
              }
            }
          }

        }

      }
      return items
    },
    headers() {
      return [
        { text : this.$t('Name')        , value : 'name' },
        { text : this.$t('Settings') , value : 'params', width : '10px'  },
        { text : this.$t('Percent')     , value : 'percent', width : '10px'  },
        { text : this.$t('Code') , value : 'code', width : '10px'  },
        { text : this.$t('Active')      , value : 'is_active' , width : '10px' },
        { value : 'action' }
      ]
    },
  },
  methods : {
    ...mapActions('discount' , [
      'GET_BUSINESS_PROMOCODES',
      'ADD_EDIT_DISCOUNT',
      'DELETE_DISCOUNT'
    ]),

    openDelete(uuid) {
      this.selectedDiscountUuid = uuid
      this.displayDelete = true
    },

    validateCode() {
      if ( this.entityData.code && !this.entityData.code.match("^[a-zA-Z0-9_-]*$")) {
        this.codeError = this.$t('PromocodeRegexError')
        this.notify(this.$t('PromocodeRegexError') , 'warning')
        return false
      }
      return true
    },
    editActiveStatus(item , status) {
      this.newDiscount = false
      this.entityData = {
        uuid : item.uuid,
        is_active : status
      }
      this.saveDiscount()
    },
    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notify(this.$t('CopiedToClipboard') )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },
    async saveDiscount() {

      if ( !this.validateCode() ) {
        return
      }

      let result = await this.ADD_EDIT_DISCOUNT(this.entityData)
      if ( !result ) {
        return
      }
      if ( this.newDiscount ) {
        this.items.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if ( index === -1 ) {
          return
        }
        this.$set(this.items, index , result)
      }

      this.displayDialog = false
    },
    async deleteDiscount() {
      let result = await this.DELETE_DISCOUNT(this.entityData.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.entityData.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index,1)
      this.displayDialog = false
      this.notify(this.$t('DiscountDeleted'))
    },
    openAddDiscount() {
      this.entityData = {
        is_promocode : true,
        type : 'default',
        range : 'global',
        percent : 10,
        courses : []
      }
      this.newDiscount = true
      this.displayDialog = true
    },
    openEditDiscount(item) {
      this.entityData = Object.assign( {} , item )
      this.newDiscount = false
      this.displayDialog = true
    }
  },
  async mounted() {
    let result = await this.GET_BUSINESS_PROMOCODES()
    if ( !result ) {
      return
    }
    this.items = result.discounts
    this.coursesSelect = result.courses
  }


}
</script>

<style scoped>

</style>